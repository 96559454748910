

.home {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    padding-top: 90px;
    padding-right: 10px;
    padding-left: 320px;
    background-color: #fff;
    background-image: url('../../assets/images/bk.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

.home_info_container {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    width: 100%;
}

.home_number_title {
    font-size: 45px;
    font-weight: 300;
    color: #1F1142;
    text-align: center;
}

.home_text_subtitle {
    font-size: 18px;
    font-weight: 600;
    color: #519DCC;
    text-align: center;
    text-transform: uppercase;
}

.home_info_box {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 50px;
    align-items: center;
    width: 281px;
    height: 281px;
    border-radius: 50%;
    background-color: #f9f9f9;
    margin-bottom: 20px; 
}

.home_menu_buttons_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    padding-bottom: 30px;
}

.home_info_box_icon{
    width: 55px;
    height: 55px;
}

@media (max-width: 920px) {
    .home_info_box {
        width: 239px;
        height: 239px;
        margin-bottom: 30px;
    }
    .home_number_title {
        font-size: 24px;
        margin-bottom: 6px;
    }
    .home_text_subtitle {
        font-size: 14px;
        margin-bottom: 6px;
    }
    .home_info_box_icon{
        width: 41px;
        height: 41px;
    }
}

@media (max-width: 850px) {
    .home_info_box {
        width: 239px;
        height: 239px;
        margin-bottom: 30px;
    }

    .home {
        padding-left: 15px;
        padding-bottom: 90px;
    }

    .home_info_container {
        justify-content: space-around;
    }

    .home_number_title {
        font-size: 24px;
        margin-bottom: 6px;
    }
    .home_text_subtitle {
        font-size: 14px;
        margin-bottom: 6px;
    }
    .home_info_box_icon{
        width: 41px;
        height: 41px;
    }
}

@media (max-width: 535px) {
    .home_info_box {
        width: 200px;
        height: 200px;
        margin-bottom: 30px;
    }
    .home_number_title{
        font-size: 16px;
        font-weight: 400;
    }
    .home_text_subtitle{
        font-size: 10px;
    }
    .home_info_box_icon{
        width: 30px;
        height: 30px;
    }
}

@media (max-width: 410px) {
    .home_info_box {
        width: 160px;
        height: 160px;
        margin-bottom: 30px;
    }
}