.card_modal{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    backdrop-filter: blur(3px);
    background: rgba(0, 0, 0, 0.2);
}

.payment_form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px;
    background-color: #fff;
    width: 450px;
    height: 400px;
    border-radius: 3px;
    position: relative;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.card_modal_close{
    font-size: 35px;
    color: #31378B;
    position: absolute;
    top: 15px;
    left: 15px;
    cursor: pointer;
}

.card_modal_title{
    font-size: 24px;
    font-weight: 300;
    text-align: center;
    color: #01949F;
    text-transform: uppercase;
    margin-bottom: 25px;
}

#card-element {
    height: 55px;
    padding-top: 18px;
    padding-left: 20px;
    padding-right: 10px;
    width: 100%;
    background: #f9f9f9;
    box-sizing: border-box;
    border-radius: 50px;
    text-transform: uppercase;
  }

.checkout_add_card_button{
    width: 100%;
    height: 55px;
    border: solid 1px #31378B;
    color: #fff;
    background-color: #31378B;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 50px;
    margin-top: 30px;
    text-transform: uppercase;
}

.checkout_add_card_button:hover{
    cursor: pointer;
    filter: brightness(105%);
}

.payment_form_card_error{
    padding-top: 20px;
    font-size: 12px;
    text-align: center;
    color: #31378B;
}
