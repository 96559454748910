.group_item{
    display: flex;
    justify-content: right;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    backdrop-filter: blur(4px);
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
}

.group_item_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fdfdfd;
    width: 40%;
    min-width: 380px;
    height: 100%;
    position: relative;
    overflow-y: scroll;
    padding-top: 60px;
    padding-bottom: 60px;
}

.group_item_main_title{
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 20px;
    color: #31378B;
 }

.group_icon_close{
    position: absolute;
    font-size: 38px;
    color: #31378B;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

.group_item_icons_add_remove{
    display: flex;
    align-items: center;
}
.group_item_add_icon{
    font-size: 45px;
    color: #31378B;
    margin-top: 15px;
    cursor: pointer;
}

.group_item_button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 310px;
    height: 55px;
    background: #31378B; 
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    border-radius: 50px;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
}

.group_food_category_selector_icon{
    position: absolute;
    font-size: 26px;
    color: #31378B;
    right: 26px;
    top: 12px;
    cursor: pointer;
}

.group_food_category_selector{
    width: 320px;
    max-width: 95%;
    height: 50px;
    padding-left: 10px;
    color: #1d2129;
    border-radius: 50px;
    margin-bottom: 10px;
    border: solid 0.5px;
    text-align: center;
    background: transparent;
    z-index: 1;
    cursor: pointer;
}