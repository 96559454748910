.history-card {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Align to the start, not center, to ensure content dictates width */
    align-items: center; /* Stretch child items to fill the width of the container */
    width: 100%; /* Take up 100% width of parent container */
    max-width: 500px;
    min-width: 280px; /* Min width of 320px */
    align-self: 'center';
    /* margin: 0 auto 20px auto;  */
    padding: 10px; 
    margin-right: 5px;
    border-radius: 15px;
    border: 2px solid #f9f9f9;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    box-sizing: border-box; /* Ensure padding is included in the element's total width and height */
}

.history-card-header {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: space-between;
    flex-direction: row;
    /* background-color: #f9f9f9; */
    padding: 10px;
    border-radius: 15px;
}

.history-card-header, .history-card-expanded-container {
    flex: none;
    width: 100%;
    max-width: none;
    box-sizing: border-box;
}

.history-card-date-container {
    background-color: #31378B;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    height: 30px;
    min-width: 165px;
    max-width: 200px;
    padding: 8px;
}

.history-card-text-date {
    font-size: 13px;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
}

.history-card-total-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.history-card-text-total {
    font-size: 14px;
    color: #31378B;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 5px;
    text-align: center;
}

.history-card-text-lbs {
    font-size: 12px;
    color: #469EAA;
    font-weight: 400;
    text-transform: uppercase;
    text-align: right;
}

.history-card-toggle-icon {
    width: 25px;
    height: 25px;
}

.history-card-expanded-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 100%;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding-bottom: 2px;
}

.history-card-expanded-donation-items {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    border-bottom: 1px solid rgb(216, 216, 216);
}

.history-card-expanded-donation-title {
    font-size: 13px;
    font-weight: 300;
    color: #31378B;
    text-transform: uppercase;
    flex: 1;
}

.history-card-expanded-donation-subtitle {
    font-size: 13px;
    font-weight: 300;
    color: #31378B;
    text-transform: uppercase;
    text-align: center;
    align-self: center;
    flex: 1;
}

.history-card-expanded-donation-lbs-text {
    font-size: 12.5px;
    font-weight: 500;
    color: #469EAA;
    text-transform: uppercase;
    text-align: right;
}

.edit-icon {
    width: 14px; /* Adjusted from normalizeByWidth(14) */
    height: 26px; /* Adjusted from normalizeByWidth(26) */
    object-fit: contain;
    margin-left: 5px;
}

.history-card-expanded-donation-totals {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 10px;
    padding: 5px;
    z-index: 1;
    border-top: 1px solid #ccc;
}

.history-card-expanded-donation-delivery-fee-text, .history-card-expanded-donation-link-text {
    font-size: 12.5px;
    font-weight: 500;
    text-transform: uppercase;
    text-align: right;
}
.history-card-expanded-donation-link-text {
    color: #469EAA;
}

.history-card-expanded-donation-delivery-fee-text {
    color: #ED896E;
}

.upcoming-add-item-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 10px;
}

.card-button {
    width: 100px; /* Adjusted from normalizeByWidth(100) */
    height: 35px; /* Adjusted from normalizeByHeight(35) */
}

.modal-background {
    display: flex;
    background-color: rgba(0, 0, 0, 0.5);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    flex: 1;
}

.modal-container {
    margin-top: 100px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    flex: 1;
    background-color: white;
}

.close-icon-container {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
}

.group-icon-close {
    font-size: 34px;
    color: #31378B;
}

.checkout-items-container {
    margin-top: 10px;
}

.empty-schedule-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 20px;
}

.empty-schedule-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #fff;
    border: 1px solid #f9f9f9;
    border-radius: 12px;
}

.empty-schedule-title {
    color: #ED896E;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 400;
    text-align: center;
}
