.subscribe_modal{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    top: 0;
    left: 0;
    z-index: 20;
    backdrop-filter: blur(5px);
    background: rgba(0, 0, 0, 0.2);
    position: fixed;
}

.subscribe_modal_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px;
    background-color: #fdfdfd;
    max-width: 420px;
    width: 100%;
    height: auto;
    border-radius: 8px;
    position: relative;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin-right: 15px;
    margin-left: 15px;
}

.subscribe_modal_icon_close{
    color: #31378B;
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
}

.subscribe_modal_title{
    font-size: 24px;
    color: #ED896E;
    font-weight: 400;
    margin-bottom: 30px;
    margin-top: 30px;
    text-transform: uppercase;
}

.subscribe_modal_subtitle{
    font-size: 14px;
    color: #31378B;
    text-align: center;
    text-transform: uppercase;
    font-weight: 400;
}

.subscribe_modal_button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 265px;
    height: 55px;
    background-color: #31378B;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    border-radius: 50px;
    margin-bottom: 10px;
    margin-top: 30px;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
}