.checkout_item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    margin-bottom: 20px;
    max-width: 823px;
    width: 100%;
    background-color: #f9f9f9;
    border-radius: 12px;
}

.checkout_item_section_one{
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-start;
}

.checkout_item_section_two{
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: center;
}


.checkout_item_section{
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-end;
}

.checkout_item_section_icon{
    display: flex;
    align-items: center;
    justify-content: flex-end; 
}

.checkout_item_name{
    font-size: 21px;
    font-weight: 300;
    color: #1F1142;
    text-align: left;
}

.checkout_item_value_lbs{
    font-size: 1.65rem;
    font-weight: 700;
    color: #ED896E;
    padding-right: 5px;
}

.checkout_item_value{
    font-size: 21px;
    font-weight: 300;
    color: #01949F;
    padding-right: 5px;
}

.checkout_item_icon{
    height: 25px;
    width: 25px;
    margin-left: 10px;
    cursor: pointer;
}

.checkout_item_icon_trash{
    color:  #162c61;
    cursor: pointer;
    font-size: 23px;
}

.checkout_item_input{
    font-size: 1rem;
    width: 80px;
    padding: 8px;
    background: #fff;
    border: solid 0.5px #31378B;
    color: #200738;
    border-radius: 12px;
    margin-right: 8px;
    text-align: center;
}

.checkout_item_lbs_input{
    font-size: 1rem;
    width: 80px;
    padding: 10px;
    background: #fff;
    border: solid 2px #31378B;
    color: #200738;
    border-radius: 5px;
}

  .checkout_item_lbs_input:focus {
    outline: none;
    border: solid 2px #31378B;
  }

 
 
  @media screen and (max-width: 600px) {
    .checkout_item_value_lbs{
        font-size: 1.3em;
    }
    .checkout_item_value{
        font-size: 1.3rem;
    }
    .checkout_item_lbs_input{
        width: 55px;
        padding: 8px;
        margin-left: 8px;
    }
    .checkout_item_icon{
        font-size: 29px;
    }
    .checkout_item_name{
        font-size: 16px;
    }
    .checkout_item_value{
        font-size: 16px;
    }
    .checkout_item_input{
        font-size: 16px;
    }

}
