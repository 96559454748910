

.checkout_estimate_modal_button_update{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 265px;
    height: 60px;
    background-color: #31378B;
    color: #fff;
    font-size: 1.3rem;
    border-radius: 50px;
    margin-bottom: 10px;
    margin-top: 30px;
}


.checkout_estimate_modal_button_retry{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 265px;
    height: 55px;
    background-color: #fff;
    border: 1px solid #31378B;
    color: #31378B;
    font-size: 16px;
    border-radius: 50px;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 10px;
    cursor: pointer;
}

.checkout_estimate_modal_title{
    font-size: 1.5rem;
    color: #ED896E;
    font-weight: 700;
    text-align: center;
}

.checkout_estimate_modal_subtitle{
    font-size: 1rem;
    color: #31378B;
    text-align: center;
    margin-top: 30px;
}

.checkout_estimate_modal_icon{
    color: #31378B;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}
