.modal_edit_donation{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    top: 0;
    left: 0;
    z-index: 20;
    backdrop-filter: blur(3px);
    background: rgba(0, 0, 0, 0.2);
    position: fixed;
}

.modal_edit_donation_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px;
    background-color: #fdfdfd;
    max-width: 420px;
    width: 100%;
    height: auto;
    border-radius: 8px;
    position: relative;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin-right: 15px;
    margin-left: 15px;
}

.modal_edit_donation_icon_close{
    color: #31378B;
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
}

.modal_edit_header{
    display: flex;
    width: 300px;
    flex-direction: column;
}

.modal_edit_donation_title{
    font-size: 20px;
    color: #ED896E;
    font-weight: 300;
    margin-bottom: 30px;
    margin-top: 15px;
    text-transform: uppercase;
}

.modal_edit_donation_input_container{
    display: flex;
    flex-direction: column;
    position: relative;
}

.modal_edit_donation_label{
    font-size: 0.7rem;
    color: #31378B;
    padding-bottom: 2px;
    text-align: left;
    margin-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    position: absolute;
    background-color: #fdfdfd;
    top: -5px;
    left: 10px;

}

.modal_edit_donation_input{
    width: 300px;
    height: 50px;
    padding-left: 10px;
    color: #1d2129;
    border-radius: 50px;
    border: solid 0.5px;
    margin-bottom: 12px;
    text-align: left;
    padding-left: 30px;
}

.modal_edit_donation_input:focus{
    outline: none;
    border: solid 1px #a7a7c7;
}

.modal_edit_button_update{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 50px;
    background-color: #31378B;
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    border-radius: 50px;
    border: none;
    cursor: pointer;
    font-weight: 600;
}

.modal_edit_button_cancel{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 50px;
    background-color: transparent;
    border: solid 1px #31378B;
    color: #31378B;
    font-size: 16px;
    text-transform: uppercase;
    border-radius: 50px;
    margin-bottom: 10px;
    margin-top: 10px;
    cursor: pointer;
    font-weight: 600;
}