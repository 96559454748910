.group_form_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.group_form_title{
    margin-bottom: 10px;
    margin-top: 20px;
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;
    color: #31378B;
}

.group_form_input{
    width: 320px;
    max-width: 95%;
    height: 50px;
    padding-left: 10px;
    color: #31378B;
    border-radius: 50px;
    border: solid 0.5px;
    margin-bottom: 10px;
    text-align: center;
}

.group_form_input:focus{
    outline: none;
    border: solid 1.5px #31378B;
}

.group_form_input_label{
    font-size: 0.7rem;
    color: #3F929D;
    padding-bottom: 2px;
    text-align: left;
    margin-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    position: absolute;
    background-color: #fdfdfd;
    top: -5px;
    left: 20px;
}

.group_form_input_container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
}