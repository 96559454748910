.delivery {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    padding-top: 90px;
    padding-right: 10px;
    padding-left: 320px;
    background-color: #fff;
    background-image: url('../../assets/images/bk.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

.delivery_container{
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 70px;
    padding-top: 30px;
}

.delivery_title{
    color: #272525;
    text-align: center;
    font-size: 3.6rem;
    font-weight: 400;
    margin-bottom: 10px;
}

@media screen and (max-width: 850px) {
    .delivery_title{
        font-size: 2.1rem;
    }
    .delivery_image{
        width: 150px;
        margin-top: 10px;
    }
    .delivery{
        padding-left: 15px;
        padding-bottom: 90px;
    }
}