.donate, .donate_modal {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    
    padding-right: 10px;
    background-color: #fff;
    background-image: url('../../assets/images/bk.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    /* overflow-x: hidden; */
}

/* Specific styles for standalone page */
.donate {
    padding-left: 320px;
    padding-top: 90px;
}

/* Specific styles for modal rendering */
.donate_modal {
    /* padding-top: 150px; */
    /* margin-top: 100px; */
    max-width: 850px;
    /* background-color: #1F1142; */
    padding: 5px;
    /* width: 95%; */
    /* justify-content: center;
    align-items: center;
    align-self: center; */
}

.header_page_title_container_donate{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-self: flex-start;
    background-color: #fff;
    width: auto;
    height: 60px;
    border-radius: 15px;
    padding: 5px;
    border: 0.5px solid #f9f9f9;
    margin-bottom: 10px;
    margin-top: 10px;
}

.page_fix_container {
    max-width: 1100px;
    width: 100%;
    padding-top: 50px;
}

.page_fix_modal {
    max-width: 850px;
    width: 98%;
    /* padding-top: 10px; */
}

.donate_header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.donate_image {
    width: 200px;
    padding-bottom: 20px;
}

.donate_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 70px;
}

.donate_title {
    color: #272525;
    text-align: center;
    font-size: 3.6rem;
    font-weight: 400;
    margin-bottom: 30px;
}

.donate_buttons_container {
    display: flex;
    justify-content: space-evenly;
    margin-top: 50px;
    padding-bottom: 30px;
    width: 100%;
}

.donate_confirm_button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 260px;
    height: 50px;
    border: solid 1px #01949F;
    color: #fff;
    background-color: #01949F;
    font-size: 1.1rem;
    font-weight: 700;
    border-radius: 12px;
    margin-bottom: 10px;
}

.donate_confirm_button:hover {
    filter: brightness(110%);
    cursor: pointer;
}

.donate_add_item_button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 260px;
    max-width: 100%;
    height: 50px;
    border: solid 1px #31378B;
    color: #fff;
    background-color: #31378B;
    font-size: 1.1rem;
    font-weight: 700;
    border-radius: 12px;
    margin-bottom: 10px;
}

.donate_add_item_button:hover {
    filter: brightness(110%);
    cursor: pointer;
}

.donate_cancel_button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 260px;
    height: 50px;
    border: solid 1px #31378B;
    color: #31378B;
    font-size: 1.1rem;
    font-weight: 700;
    border-radius: 12px;
    background-color: #fdfdfd;
    margin-bottom: 10px;
}

.donate_cancel_button:hover {
    filter: brightness(110%);
    cursor: pointer;
}

.donate_bag_item_section {
    display: flex;
    width: 100%;
    justify-content: center;
}

.donate_bag_item_container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 40px;
    width: 100%;
    max-width: 900px;
}

.no_results_item_container {
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 100%;
    margin-top: 40px;
}

.no_results_item_title {
    color: #31378B;
    font-weight: 600;
    font-size: 1.4rem;
    text-align: center;
    margin-bottom: 8px;
}

.no_results_item_subtitle {
    font-weight: 400;
    font-size: 1rem;
    text-align: center;
    margin-bottom: 8px;
    color: #2d2d2d;
}

.donate_info_box {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 50px;
    align-items: center;
    width: 281px;
    height: 281px;
    border-radius: 50%;
    background-color: #f9f9f9;
    margin-bottom: 20px;
}

.donate_number_title {
    font-size: 45px;
    font-weight: 300;
    color: #1F1142;
    text-align: center;
}

.donate_text_subtitle {
    font-size: 18px;
    font-weight: 600;
    color: #519DCC;
    text-align: center;
    text-transform: uppercase;
}

.donate_buttons_main {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 265px;
    height: 55px;
    color: #fff;
    font-size: 16px;
    border-radius: 50px;
    border: none;
    font-weight: 500;
    text-transform: uppercase;
}

@media screen and (max-width: 1220px) {
    .donate_buttons_container {
        justify-content: space-around;
        margin-top: 0px;
        margin-bottom: -15px;
    }

    .donate_info_box {
        width: 165px;
        height: 165px;
        margin-bottom: 30px;
    }

    .donate_number_title {
        font-size: 18px;
        font-weight: 400;
    }

    .donate_text_subtitle {
        font-size: 12px;
    }
    .donate_buttons_main{
        width: 165px;
        height: 50px;
        font-size: 15px;
    }

    
}

@media screen and (max-width: 850px) {
    .donate, .donate_modal {
        padding-left: 15px;
        padding-bottom: 90px;
        /* ... */
    }
}

@media screen and (max-width: 850px) {
    .donate_buttons_container {
        justify-content: space-around;
        margin-top: 0px;
        margin-bottom: -15px;
    }

    .donate {
        padding-left: 15px;
        padding-bottom: 90px;
    }

    .donate_info_box {
        width: 165px;
        height: 165px;
        margin-bottom: 30px;
    }

    .donate_number_title {
        font-size: 18px;
        font-weight: 400;
    }

    .donate_text_subtitle {
        font-size: 12px;
    }
}

@media (max-width: 535px) {
    .page_fix_container {
        padding-top: 20px;
    }

    .donate_info_box {
        width: 130px;
        height: 130px;
        margin-bottom: 30px;
    }

    .donate_number_title {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 8px;
    }

    .donate_text_subtitle {
        font-size: 10px;
    }
    .donate_buttons_main{
        width: 130px;
        height: 45px;
        font-size: 14px;
    }
}

@media (max-width: 430px) {
    .donate_info_box {
        width: 100px;
        height: 100px;
        margin-bottom: 30px;
    }

    .donate_number_title {
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 8px;
    }

    .donate_text_subtitle {
        font-size: 9px;
    }
    .donate_buttons_main{
        width: 100px;
        height: 40px;
        font-size: 12px;
    }
}