.header {
    height: 90px;
    width: 100vw;
    position: fixed;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    background-color: #fff;
}

.header_logo {
    height: 50px;
    width: 50px;
    border-radius: 5px;
}

.header_logo_right{
    width: 140px;
    height: 60px;
    margin-top: 6px;
}

.header_flex_logo{
    display: flex;
    justify-content: center;
}

.header_flex_icon{
    display: flex;
    padding-left: 40px;
}

.header_container {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    padding-right: 40px;
    width: 100%;
    padding-left: 320px;
    padding-top: 15px;
}

.header_page_title_container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-self: flex-start;
}

.header_page_title{
    font-size: 25px;
    text-transform: uppercase;
    color: #3F929D;
    font-weight: 400;
    text-align: center;
    width: auto;
}

.header_page_title_icon_home{
    width: 38px;
    height: 38px;
    margin-right: 15px;
}

.header_page_title_icon_history{
    width: 45px;
    height: 45px;
    margin-right: 15px;
}

.header_page_title_icon_box{
    width: 57px;
    height: 57px;
    margin-right: 15px;
}

.header_page_title_icon_account{
    width: 40px;
    height: 40px;
    margin-right: 15px;
}

.header_page_title_icon_card{
    width: 43px;
    height: 43px;
    margin-right: 15px;
}

.header_page_title_icon_car{
    width: 60px;
    height: 60px;
    margin-right: 15px;
}

.header_icon_menu{
    margin-left: 0px;
    width: 40px;
    height: 25px;
}

.header_nav_icons {
    display: flex;
    align-items: center;
}

.header_nav_icons_checkout {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.header_nav_icons_checkout_count {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ED896E;
    color: #fff;
    padding: 3px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    font-size: 0.6rem;
    font-weight: 700;
    top: -5px;
    right: -5px;
}

.header_nav_icons_checkout_count_icon{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ED896E;
    color: #fff;
    padding: 3px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    font-size: 0.6rem;
    font-weight: 700;
    top: 0px;
    right: -2px; 
}

.header_logo_icon{
    width: 60px;
    height: 60px;
}

.header_logo_icon_container{
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    align-items: center;
    padding-top: 30px;
}

.header_logo_icon_container_mobile{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding-top: 2px;
    padding-left: 15px;
    padding-right: 15px;
}

.icon_shop {
    margin-left: 10px;
    cursor: pointer;
    height: 49px;
    width: 54px;
    padding-bottom: 5px;
}

.drawer_container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    width: 296px;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.drawer_menu {
    width: 296px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
}

.drawer_container_mobile{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    background-color: #f9f9f9;
    z-index: 10;
}

.drawer_user_name_circle{
    width: 100px;
    height: 100px;
    background-color: #31378B;
    border-radius: 50%;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.drawer_user_name{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}

.drawer_user_name_circle_text{
    font-size: 45px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 300;
}

.drawer_user_name_title{
    font-size: 20px;
    color: #3F929D;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 20px;
    text-align: center;
    align-self: center;
    padding-left: 20px;
    padding-right: 20px;
}

.drawer_menu_item {
    display: flex;
    align-items: center;
    padding: 10px;
    padding-left: 20px;
    width: 170px;
    border-radius: 50px;
    color: #31378B;
    height: 50px;
}

.drawer_menu_item_icon{
    width: 32px;
    height: 32px;
}

.drawer_menu_item_name {
    color: #31378B;
    font-weight: 500;
    font-size: 16px;
    margin-left: 20px;
    text-transform: uppercase;
}

.drawer_menu_item_name_active{
    color: #ED896E;
    font-weight: 600;
    font-size: 16px;
    margin-left: 20px;
    text-transform: uppercase;
}

.menu_notification_title{
    color: #ED896E;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
    margin-bottom: 20px;
}

.drawer_menu_item_inactive {
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding: 10px;
    width: 300px;
    border-radius: 3px;
    color: #31378B;
    height: 45px;
    margin-top: 10px;
}

.drawer_menu_item_name_inactive {
    color: #777;
    font-weight: 500;
    font-size: 0.90rem;
    margin-left: 30px;
}

.drawer_menu_item_icon_inactive {
    color: #777;
    margin-left: 10px;
}


.drawer_menu_buttons_container{
    margin-bottom: 40px;
}

.icon_delivery{
    margin-left: 15px;
    cursor: pointer;
    animation-name: delivery_active;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
    height: 35px;
    width: 35px;
}

.mobile_nav_bar{
    background-color: #f9f9f9;
    height: 90px;
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 5;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.mobile_nav_item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mobile_nav_icon{
    width: 31px;
    height: 31px;
}

.mobile_nav_title{
    font-size: 12px;
    font-weight: 400;
    color: #31378B;
    text-transform: uppercase;
    padding-top: 5px;
}

.mobile_nav_title_active{
    font-size: 12px;
    font-weight: 400;
    color: #ED896E;
    text-transform: uppercase;
    padding-top: 5px;
}


@media (max-width: 1000px) {
    .drawer_container{
        width: 270px;
    }
    .header_page_title{
        font-size: 16px;
    }
}

@media (max-width: 850px) {
    .header{
        height: 80px;
    }
    .header_container {
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 0;
      justify-content: space-between;
    }
    .header_page_title{
        font-size: 16px;
        font-weight: 500;
    }
    .header_page_title_icon_history{
        height: 35px;
        width: 35px;
        margin-right: 10px;
    }
    .header_page_title_icon_box{
        height: 40px;
        width: 40px;
        margin-right: 10px;
    }
    .header_page_title_icon_account{
        height: 35px;
        width: 35px;
        margin-right: 10px;
    }
    .header_page_title_icon_card{
        width: 31px;
        height: 31px;
        margin-right: 10px;
    }
    .header_page_title_icon_car{
        width: 50px;
        height: 50px;
        margin-right: 10px;
    }
    .header_page_title_container{
        background-color: #fff;
        width: auto;
        height: 60px;
        border-radius: 15px;
        padding: 5px;
        border: 0.5px solid #f9f9f9;
    }
}