.delevery_tracking {
    width: 100%;
    max-width: 794px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f9f9f9;
    border-radius: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.delevery_tracking_title {
    font-size: 22px;
    color: #519DCC;
    font-weight: 600;
    text-align: left;
    text-transform: uppercase;
}

.delivery_tracking_flow_container{
    display: flex;
    flex-direction: column;
}

.delevery_tracking_time_container {
    display: flex;
    align-items: center;
    width: 90%;
}

.delivery_tracking_cancel{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-right: 5px;
}

.delevery_tracking_time {
    font-size: 1.8rem;
    color: #31378B;
}

.delevery_tracking_time_description {
    font-size: 0.85rem;
    color: #31378B;
    margin-right: 5px;
    font-weight: 300;
    margin-bottom: 10px;
    text-transform: uppercase;
}


.delivery_tracking_step_container {
    display: flex;
    width: 90%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.delivery_tracking_step {
    flex: 1;
    background-color: #469EAA;
    border-radius: 8px;
    height: 4px;
    padding-right: 5px;
    padding-left: 5px;
    width: 99%;
    margin-right: 5px;
}

.delivery_tracking_step_active {
    flex: 1;
    background-color: #469EAA;
    border-radius: 8px;
    height: 4px;
    padding-right: 5px;
    padding-left: 5px;
    animation-name: color;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    width: 99%;
    margin-right: 5px;
}

.delivery_tracking_step_inactive {
    flex: 1;
    width: 99%;
    border-radius: 8px;
    background-color: #a1a1a1;
    height: 4px;
    padding-right: 5px;
    padding-left: 5px;
    margin-right: 5px;
}

.delivery_tracking_step_canceled{
    flex: 1;
    width: 99%;
    border-radius: 8px;
    background-color: #ED896E;
    height: 4px;
    padding-right: 5px;
    padding-left: 5px;
    margin-right: 5px;
}

.delevery_tracking_status_container {
    display: flex;
    width: 90%;
    margin-bottom: 20px;
    justify-content: space-between;
    margin-right: 5px;
}

.delevery_tracking_status_title {
    font-size: 16px;
    font-weight: 600;
    color: #31378B;
    margin-top: 10px;
    text-transform: uppercase;
}

.delevery_tracking_status_time {
    font-size: 14px;
    font-weight: 300;
    color: #31378B;
    margin-top: 5px;
    text-transform: uppercase;
}

.delevery_tracking_status_id {
    font-size: 14px;
    font-weight: 300;
    color: #31378B;
    margin-top: 5px;
    text-align: right;
    text-transform: uppercase;
}

.delevery_tracking_status_link {
    font-size: 16px;
    font-weight: 600;
    padding-top: 10px;
    color: #519DCC;
    text-decoration: none;
    text-align: right;
    cursor: pointer;
    text-transform: uppercase;
}

.delevery_tracking_status_link_canceled{
    font-size: 0.95rem;
    font-weight: 300;
    padding-top: 10px;
    color: #ED896E;
    text-decoration: underline;
    text-align: right;
    cursor: pointer;
}

.delevery_tracking_close_button_canceled{
    width: 260px;
    height: 45px;
    border-radius: 10px;
    color: #ED896E;
    border: solid 2px #ED896E;
    font-size: 1.1rem;
    font-weight: 700;
    background-color: transparent;
    margin-bottom: 10px;
    cursor: pointer;
}

.delevery_tracking_close_button{
    width: 260px;
    height: 45px;
    border-radius: 12px;
    color: #469EAA;
    border: solid 2px #469EAA;
    font-size: 1.1rem;
    font-weight: 700;
    background-color: transparent;
    margin-bottom: 10px;
    cursor: pointer;
}

.delivery_cancel_donation_button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
    padding: 10px;
    background-color: transparent;
    border: solid 1px #ED896E;
    color: #ED896E;
    font-size: 14px;
    font-weight: 600;
    border-radius: 30px;
    margin-bottom: 10px;
    margin-top: 10px;
    cursor: pointer;
    text-transform: uppercase;
}

@keyframes color {
    0% {
        background-color: #02ccda;
    }

    25% {
        background-color: #04b5c2;
    }

    50% {
        background-color: #469EAA;
    }

    75% {
        background-color: #027e87;
    }

    100% {
        background-color: #469EAA;
    }
}

@media screen and (max-width: 800px) {
    .delevery_tracking{
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .delevery_tracking_title{
        font-size: 16px;
    }
    .delevery_tracking_status_title{
        font-size: 11px;
    }
    .delevery_tracking_status_time{
        font-size: 10px;
    }
    .delevery_tracking_status_id{
        font-size: 10px;
    }
    .delevery_tracking_status_link{
        font-size: 11px;
    }
}