.account{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 90px;
  padding-right: 10px;
  padding-left: 320px;
  background-color: #fff;
  background-image: url('../../assets/images/bk.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.account_container{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 50px;
}

.account_section_content{
  max-width: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  background-color: #fff;
  border: solid 1px #f9f9f9;
  padding: 20px;
  border-radius: 12px;
}

.account_update_password_title{
    color: #3F929D;
    font-size: 20px;
    text-transform: uppercase;
    margin-bottom: 20px
}

.account_billing_button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 280px;
    height: 55px;
    background-color: #31378B;
    color: #fff;
    font-size: 16px;
    border-radius: 50px;
    border: none;
    font-weight: 500;
    text-transform: uppercase;
}

.account_password_form{
    width: 280px;
    position: relative;
}

.account_password_input{
    font-size: 16px;
    height: 55px;
    border: none;
    width: 100%;
    padding-left: 20px;
    background: #f9f9f9;
    color: #1d2129;
    border-radius: 50px;
    text-transform: uppercase;
    border: solid 1px #f9f9f9;
    margin-bottom: 10px

  }

  .account_password_input:focus {
    outline: none;
    border: solid 1px #1d2129;
  }

  .account_icon_view{
    position: absolute;
    top: 20px;
    right: 18px;
    color: rgb(142, 142, 142);
    cursor: pointer;
  }

  .account_instruction_area{
    max-width: 280px;
    width: 100%;
    border: solid 1px #f9f9f9;
    resize: none;
    background: #f9f9f9;
    font-size: 16px;
    background: #f9f9f9;
    border-radius: 12px;
    padding: 10px;
    height: auto;
  }

  .account_instruction_area:focus {
    outline: none;
    border: solid 1px #1d2129;
  }

.account_updated_success{
  font-size: 13px;
  margin-left: 5px;
  font-weight: 400;
  margin-top: 15px;
  color: #3F929D;
}


@media (max-width: 850px) {
  .account{
      padding-left: 15px;
      padding-bottom: 90px;
  }
  .account_icon_view{
    top: 20px
  }

}
