.recipient_select{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: auto;
    position: relative;
    margin-bottom: 10px;
    margin-right: 10px;
    margin-left: 10px;
}

.recipient_select_container{
    display: flex;
    width: auto;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    background-color: #EB896C;
    width: 265px;
    height: 55px;
    border-radius: 50px;
    z-index: 7;
}

.recipient_icon_containers{
    margin-left: 15px;
    margin-top: 5px;
}

.recipient_select_close_icon{
    font-size: 25px;
    cursor: pointer;
    color: #fff;
    position: absolute;
    top: 15px;
    right: 10px;
}

.recipient_select_chevron_icon{
    font-size: 25px;
    cursor: pointer;
    color: #fff;
    position: absolute;
    top: 15px;
    right: 10px;
}

.recipient_select_container_option{
    width: 265px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 10px;
    margin-top: -50px;
    background-color: #fff;
    border: solid 1px #EB896C;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    z-index: 5;
    position: absolute;
    top: 80px;
}

.recipient_option_title{
    font-size: 15px;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
}

.recipient_option_title_lower{
    font-size: 12px;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    max-width: 170px;
}

.recipiton_option_button{
    width: 100%;
    height: 35px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    color: #EB896C;
    font-size: 15px;
    font-weight: 500;  
    text-transform: uppercase;
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 10px;
}