.billing {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    padding-top: 90px;
    padding-right: 10px;
    padding-left: 320px;
    background-color: #fff;
    background-image: url('../../assets/images/bk.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

.billing_header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
    padding-top: 30px;
    width: 100%;
    margin-bottom: 30px;
}

.billing_image{
    width: 150px;
}

.billing_title{
    color: #272525;
    text-align: center;
    font-size: 3.6rem;
    font-weight: 400;
    margin-top: 40px;
    margin-bottom: 40px;
}

.billing_payment_container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 281px;
    height: 281px;
    border-radius: 50%;
    background-color: #f9f9f9;
    margin-bottom: 50px;
   
}

.billing_main_container{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

.biling_card_text{
    font-size: 18px;
    color: #519DCC;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 20px;
}

.billing_card_edit_btn{
    width: 130px;
    border-radius: 30px;
    height: 20px;
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
    background-color: #519DCC;
    border: none;
    font-weight: 600;
    margin-bottom: 10px;
    cursor: pointer;
}

.billing_card_remove_btn{
    width: 130px;
    border-radius: 30px;
    height: 20px;
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
    background-color: #469EAA;
    border: none;
    font-weight: 600;
    cursor: pointer;
}

.biling_card_brand{
    width: 50px;
}

.billing_footer{
    display: flex;
    justify-content: center;
    align-items: center;
}

.billing_stripe_image{
    max-width: 300px;
    width: 100%;
    margin-top: 20px;
}

.billing_subscription_title{
    color: #31378B;
    font-size: 1.4rem;
    margin-bottom: 10px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
}

.billing_subscription_description{
    text-align: center;
    color: #31378B;
    font-size: 0.9rem;
    max-width: 400px;
    margin-bottom: 30px;
}

@media screen and (max-width: 850px) {
    .checkout_header_section{
        margin-bottom: 15px;
    }
    .billing_title{
        font-size: 2.1rem;
    }
    .billing_image{
        width: 130px;
        margin-top: 10px;
    }
    .billing{
        padding-left: 15px;
        padding-bottom: 90px;
    }
    
}
