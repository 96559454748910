.add_item_modal{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 30;
    backdrop-filter: blur(3px);
    background: rgba(0, 0, 0, 0.2);
    padding-left: 320px;
}

.add_item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px;
    background-color: #fff;
    width: 450px;
    height: 400px;
    border-radius: 3px;
    position: relative;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.add_item_close{
    position: absolute;
    top: 15px;
    left: 15px;
    cursor: pointer;
    color: #31378B;
}

.add_item_title{
    font-size: 24px;
    font-weight: 300;
    text-transform: uppercase;
    margin-bottom: 30px;
    color: #01949F;
}

.add_item_input{
    width: 320px;
    height: 50px;
    padding-left: 20px;
    color: #31378B;
    border-radius: 50px;
    border: none;
    margin-bottom: 10px;
    text-align: left;
    background-color: #f9f9f9;
    text-transform: uppercase;
    font-size: 16px;
}

.add_item_input:focus{
    outline: none;
    border: solid 1px #200738;
}

.add_item_button{
    width: 320px;
    max-width: 95%;
    height: 55px;
    border: none;
    color: #fff;
    background-color: #31378B;
    font-size: 16px;
    font-weight: 600;
    border-radius: 50px;
    margin-bottom: 10px;
    margin-top: 20px;
    text-transform: uppercase;
}

.add_item_alert{
    font-size: 0.85rem;
    color: #ED896E;
}

.add_item_button:hover{
    filter: brightness(110%);
    cursor: pointer;
}

@media screen and (max-width: 800px) {
   .add_item_modal{
    padding-left: 10px;
    padding-right: 10px;
   }
    
}

