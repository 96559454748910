.custom_button_green{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 265px;
    height: 55px;
    background-color: #469EAA;
    color: #fff;
    font-size: 16px;
    border-radius: 50px;
    border: none;
    font-weight: 500;
    text-transform: uppercase;
}

.custom_button_orange{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 265px;
    height: 55px;
    background-color: #EB896C;
    color: #fff;
    font-size: 16px;
    border-radius: 50px;
    border: none;
    font-weight: 500;
    text-transform: uppercase;
}

.custom_button_blue{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 265px;
    height: 55px;
    background-color: #31378B;
    /* background-color: #1E0C38; */
    color: #fff;
    font-size: 16px;
    border-radius: 50px;
    border: none;
    font-weight: 500;
    text-transform: uppercase;
}

.custom_button_gradient-blue{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 265px;
    height: 55px;
    background-image: linear-gradient(to right,#33398c, #200738);
    color: #fff;
    font-size: 16px;
    border-radius: 50px;
    border: none;
    font-weight: 500;
}

.custom_button_gradient-green{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 265px;
    height: 55px;
    background-image: linear-gradient(to right, #01a2ae, #005056);
    color: #fff;
    font-size: 16px;
    border-radius: 50px;
    border: none;
    font-weight: 500;
}


.custom_button_white{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 265px;
    height: 55px;
    background: #fff;
    border: solid 1px #31378B;
    color: #31378B;
    font-size: 16px;
    border-radius: 50px;
    font-weight: 600;
    text-transform: uppercase;
}

.custom_button_blue:hover{
    cursor: pointer;
    filter: brightness(105%);
}
.custom_button_green:hover{
    cursor: pointer;
    filter: brightness(105%);
}
.custom_button_gradient-blue:hover{
    cursor: pointer;
    filter: brightness(105%);
}
.custom_button_gradient-green:hover{
    cursor: pointer;
    filter: brightness(105%);
}

.custom_button_white:hover{
    cursor: pointer;
    filter: brightness(105%);
}
