.donate_express_text {
    font-size: 1.7rem;
    font-weight: 400;
    color: #01949F;
    margin-bottom: 40px;
    text-align: center;
    max-width: 550px;
    width: 100%;
    margin-top: 40px;
}

.checkout_confirm_modal {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    backdrop-filter: blur(2px);
    background: rgba(0, 0, 0, 0.5);
}

.checkout_confirm_modal_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px;
    background-color: #fdfdfd;
    width: 350px;
    height: 350px;
    border-radius: 8px;
    position: relative;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

}

.checkout_confirm_modal_title{
    font-size: 1.5rem;
    color: #ED896E;
    font-weight: 700;
    text-align: center;
}

.checkout_confirm_modal_subtitle{
    font-size: 1rem;
    color: #31378B;
    text-align: center;
    margin-top: 30px;
}

.checkout_recipient_title_text{
    font-size: 24px;
    font-weight: 400;
    color: #272525;
    text-align: center;
    margin-right: 10px;
}

.checkout_confirm_modal_button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 260px;
    height: 50px;
    background-color: #31378B;
    color: #fff;
    font-size: 1.1rem;
    font-weight: 700;
    border-radius: 12px;
    margin-top: 30px;
}

.checkout_confirm_modal_icon{
    color: #31378B;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.chekout_recipent_container{
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin-bottom: 50px;
}

@media screen and (max-width: 600px) {
    .donate_title{
        font-size: 2rem;
        text-align: center;
    }
    .donate_express_text{
        font-size: 1.2rem;
    }
}