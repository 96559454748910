.bag_item_empty{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 240px;
    height: 240px;
    border-radius: 50%;
    background-color: #31378B;
    color: #fff;
    position: relative;
    margin-bottom: 20px;

}

.bag_item_filled{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 240px;
    height: 240px;
    border-radius: 50%;
    background-color: #EB896C;
    color: #fff;
    margin-bottom: 20px;

}

.bag_item_edit{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 240px;
    height: 240px;
    border-radius: 15px;
    background-color: #31378B;
    color: #fff;
    padding: 15px;
    padding-top: 25px;
    margin: 15px;
    position: relative;
    margin-bottom: 20px;
}

.bag_item_edit_added{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 240px;
    height: 240px;
    border-radius: 15px;
    background-color: #EB896C;
    color: #fff;
    padding: 15px;
    margin: 15px;
    position: relative;
    margin-bottom: 20px;
}

.bag_item_title{
    font-size: 17px;
    font-weight: 600;
    text-transform: uppercase;
    padding-bottom: 5px;
    text-align: center;
    max-width: 80px;
}

.bag_item_subtitle{
    font-size: 17px;
    font-weight: 600;
}

.bag_item_edit_add{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}

.bag_item_edit_add_input{
    width: 100px;
    height: 42px;
    padding-left: 5px;
    padding-right: 5px;
    color: #1d2129;
    border-radius: 8px;
    border: none;
    text-align: center;
}

.bag_item_edit_add_input:focus{
    outline: none;
}

.bag_item_edit_add_check_icon{
    width: 42px;
}

.bag_item_edit_price_button{
    width: 100%;
    height: 45px;
    border: solid 1px #01949F;
    color: #fff;
    background-color: #01949F;
    font-size: 15px;
    font-weight: 500;
    border-radius: 8px;
    margin-bottom: 8px;
    margin-top: 8px;
    text-transform: uppercase;
}

.bag_item_edit_delete_button{
    width: 100%;
    height: 45px;
    border: solid 1px #31378B;
    color: #31378B;
    font-size: 0.9rem;
    font-weight: 700;
    border-radius: 5px;
}

.bag_item_edit_price_button, 
.bag_item_edit_delete_button,
.bag_item_filled,
.bag_item_empty,
.bag_item_edit_add_check_icon:hover{
    cursor: pointer;
    filter: brightness(105%);
}

.bag_item_edit_section{
    display: flex;
    align-items: center;
    justify-content: center;
}

.bag_item_lb_text{
    font-weight: 600;
    font-size: 17px;
    margin-right: 12px;
}

.bag_item_icon_close{
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
    color: #FFF;
    font-size: 28px;
}

.bag_item_icon_edit{
    cursor: pointer;
    color: #FFF;
    font-size: 28px;
}

.bag_item_modal {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    top: 0;
    left: 0;
    z-index: 20;
    backdrop-filter: blur(3px);
    background: rgba(0, 0, 0, 0.2);
    position: fixed;
}

.bag_item_modal_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px;
    background-color: #fdfdfd;
    max-width: 420px;
    width: 100%;
    height: auto;
    border-radius: 8px;
    position: relative;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin-right: 15px;
    margin-left: 15px;
}

.bag_item_modal_title{
    font-size: 20px;
    color: #ED896E;
    font-weight: 300;
    margin-bottom: 30px;
    margin-top: 15px;
    text-transform: uppercase;
}

.bag_item_modal_subtitle{
    font-size: 14px;
    color: #31378B;
    text-align: center;
    text-transform: uppercase;
    font-weight: 400;
}

.bag_modal_button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 265px;
    height: 55px;
    background-color: #31378B;
    color: #fff;
    font-size: 16px;
    border-radius: 50px;
    border: none;
    font-weight: 500;
    text-transform: uppercase;
    margin-top: 20px;
    margin-bottom: 10px;
    cursor: pointer;
}

@media screen and (max-width: 1200px) {
    .bag_item_empty{
        height: 165px;
        width: 165px;
    }
    .bag_item_filled{
        height: 165px;
        width: 165px;
    }
    .bag_item_title{
        font-size: 14px;
    }
    .bag_item_subtitle{
        font-size: 14px;
    }
    .bag_item_lb_text{
        font-size: 14px;
    }
    .bag_item_edit_add_check_icon{
        width: 20px;
    }

    .bag_item_edit, .bag_item_edit_added{
        width: 165px;
        height: 165px;
    }
    .bag_item_edit_add_input{
        height: 25px;
        width: 40px;
    }
    .bag_item_edit_price_button{
        height: 25px;
        font-size: 10px;
        padding: 3px;
    }
    .bag_item_icon_close{
        font-size: 24px;
    }
    .bag_item_icon_edit{
        font-size: 22px;
    }
}


@media screen and (max-width: 800px) {
    .bag_item_empty{
        height: 135px;
        width: 135px;
    }
    .bag_item_filled{
        height: 135px;
        width: 135px;
    }
    .bag_item_title{
        font-size: 12px;
    }
    .bag_item_subtitle{
        font-size: 12px;
    }
    .bag_item_lb_text{
        font-size: 12px;
    }
    .bag_item_edit_add_check_icon{
        width: 20px;
    }
    .bag_item_edit, .bag_item_edit_added{
        width: 165px;
        height: 165px;
    }
    .bag_item_edit_add_input{
        height: 25px;
        width: 40px;
    }
    .bag_item_edit_price_button{
        height: 25px;
        font-size: 10px;
        padding: 3px;
    }

    .bag_item_edit, .bag_item_edit_added{
        width: 165px;
        height: 165px;
    }
    .bag_item_edit_add_input{
        height: 25px;
        width: 40px;
    }
    .bag_item_edit_price_button{
        height: 25px;
        font-size: 10px;
        padding: 3px;
    }
    .bag_item_icon_close{
        font-size: 24px;
    }
    .bag_item_icon_edit{
        font-size: 22px;
    }
}

@media (max-width: 535px) {
    .bag_item_empty{
        height: 130px;
        width: 130px;
    }
    .bag_item_filled{
        height: 130px;
        width: 130px;
    }
    .bag_item_title{
        font-size: 12px;
    }
    .bag_item_subtitle{
        font-size: 12px;
    }
    .bag_item_lb_text{
        font-size: 12px;
    }
    .bag_item_edit_add_check_icon{
        width: 20px;
    }
    .bag_item_edit, .bag_item_edit_added{
        width: 165px;
        height: 165px;
    }
    .bag_item_edit_add_input{
        height: 25px;
        width: 40px;
    }
    .bag_item_edit_price_button{
        height: 25px;
        font-size: 10px;
        padding: 3px;
    }
    .bag_item_icon_close{
        font-size: 24px;
    }
    .bag_item_icon_edit{
        font-size: 24px;
    }
}

@media (max-width: 430px) {
    .bag_item_empty{
        height: 100px;
        width: 100px;
    }
    .bag_item_filled{
        height: 100px;
        width: 100px;
    }
    .bag_item_title{
        font-size: 9px;
    }
    .bag_item_subtitle{
        font-size: 12px;
    }
    .bag_item_lb_text{
        font-size: 12px;
    }
    .bag_item_edit_add_check_icon{
        width: 20px;
    }

    .bag_item_edit, .bag_item_edit_added{
        width: 120px;
        height: 130px;
    }
    .bag_item_edit_add_input{
        height: 25px;
        width: 40px;
    }
    .bag_item_edit_price_button{
        height: 25px;
        font-size: 10px;
        padding: 3px;
    }
    .bag_item_icon_close{
        font-size: 19px;
    }
    .bag_item_icon_edit{
        font-size: 19px;
    }
}
