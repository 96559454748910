.checkout {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    padding-top: 90px;
    padding-right: 10px;
    padding-left: 320px;
    background-color: #fff;
    background-image: url('../../assets/images/bk.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

.checkout_header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
}

.checkout_main_buttons_container{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.checkout_main_buttons_container_optional{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 100px;
}

.checkout_image{
    width: 200px;
    padding-bottom: 50px;
}

.checkout_header_container{
    display: flex;
    width: 100%;
    max-width: 700px;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 30px;
    padding: 10px;
}

.checkout_header_title_number{
    font-size: 3rem;
    font-weight: 700;
    color: #01949F;
    text-align: center;
}

.checkout_header_title_text{
    font-size: 24px;
    font-weight: 400;
    color: #272525;
    text-align: center;
    margin-bottom: 15px;
}

.checkout_items_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
}

.checkout_header_section{
    background-color: #01949F;
}

@media screen and (max-width: 1220px) {
    .checkout_main_buttons_container{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 0px;
        margin-bottom: -15px;
        flex-wrap: nowrap;
        height: auto;
    }

    .checkout_main_buttons_container_optional{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 0px;
        margin-bottom: -15px;
        flex-wrap: nowrap;
        height: auto;
    }
}

@media screen and (max-width: 850px) {
    .checkout_header_title_number{
        font-size: 2.4rem;
    }
    .checkout_header_section{
        margin-bottom: 15px;
    }
    .checkout_header_container{
        flex-wrap: wrap;
    }
    .checkout_header_title_number{
        min-width: 300px;
    }
    .checkout{
        padding-left: 15px;
        padding-bottom: 90px;
        padding-top: 100px;
    }
    .checkout_main_buttons_container{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
    }
    .checkout_main_buttons_container_optional{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
    }
}
