.history {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    padding-top: 90px;
    padding-right: 10px;
    padding-left: 320px;
    background-color: #fff;
    background-image: url('../../assets/images/bk.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

.history_header {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    margin-top: 50px;
}

.history_image {
    width: 150px;
}

.history_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 70px;
}

.history_title {
    color: #272525;
    text-align: center;
    font-size: 3.6rem;
    font-weight: 400;
}

.history_card_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    width: 100%;
}

.history_report_select_container {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
}

.history_report_select_icon {
    position: absolute;
    color: #31378B;
    font-size: 24px;
    right: 15px;
    top: 15px;
    cursor: pointer;
}

.history_report_container {
    display: flex;
    flex-direction: column;
}

.history_report_select {
    text-align: center;
    width: 280px;
    height: 52px;
    border-radius: 15px;
    margin-bottom: 12px;
    font-size: 1rem;
    border-radius: 50px;
    cursor: pointer;
    background: #fff;
    border: solid 0.5px;
}

.history_report_button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 280px;
    height: 55px;
    background-color: #31378B;
    color: #fff;
    font-size: 16px;
    border-radius: 50px;
    border: none;
    cursor: pointer;
    margin-bottom: 8px;
    text-transform: uppercase;
    font-weight: 500;
}

.history_report_button_green {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 280px;
    height: 55px;
    background-color: #469EAA;
    color: #fff;
    font-size: 16px;
    border-radius: 50px;
    border: none;
    cursor: pointer;
    margin-bottom: 8px;
    text-transform: uppercase;
    font-weight: 500;
}

.history_report_modal {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    top: 0;
    left: 0;
    z-index: 20;
    backdrop-filter: blur(5px);
    background: rgba(0, 0, 0, 0.2);
    position: fixed;
}

.history_report_modal_box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px;
    background-color: #fdfdfd;
    max-width: 420px;
    width: 100%;
    height: auto;
    border-radius: 8px;
    position: relative;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin-left: 296px;
}

@media screen and (max-width: 1220px) {
    .history_report_modal_box{
        margin-left: 0;
    }
}

@media (max-width: 850px) {
    .history {
        padding-left: 15px;
        padding-bottom: 90px;
    }
    .history_report_modal_box{
        margin-left: 0;
    }

}