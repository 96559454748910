.modal_edit_donation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* min-height: 100vh; */
  top: 0;
  left: 0;
  z-index: 20;
  backdrop-filter: blur(3px);
  border-radius: 15px;
  border: 2px solid #f9f9f9;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: fixed;
}

.modal_edit_donation_container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 25px; */
  background-color: #f9f9f9;
  /* max-width: 420px; */
  width: 100%;
  /* height: auto; */
  /* border-radius: 8px; */
  position: relative;
  border-radius: 15px;
  border: 2px solid #f9f9f9;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  /* margin-right: 15px;
  margin-left: 15px; */
}

.section_title, .section_inputs, .section_buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  margin-bottom: 10px;
  padding: 5px;
  width: 100%;
  border-radius: 8px;
}

.section_title {
  /* height: auto;  */
}
.section_inputs {
  /* height: 250px;  */
}
.section_buttons {
  /* height: 150px;  */
}


.modal_edit_donation_title {
  font-size: 24px;
  color: #31378B;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
  text-transform: uppercase;
  justify-content: center;
}

.group_icon_close {
  position: absolute;
  font-size: 30px;
  color: #31378B;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.history_report_select_container {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
}

.modal_edit_schedule_label {
  font-size: 14px;
  color: #31378B;
  font-weight: 300;
  /* margin-bottom: 10px;
  margin-top: 10px; */
  text-transform: uppercase;
  background-color: #fff;
  /* position: relative;
  margin-bottom: 5px; */
}

.group_food_category_selector_icon {
  position: absolute;
  font-size: 26px;
  color: #31378B;
  right: 4px;
  top: 22px;
  cursor: pointer;
}

.cadence_selector {
  width: 200px;
  max-width: 200px;
  height: 50px;
  /* padding-left: 10px; */
  color: #31378B;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  border: '1px solid #EB896C';
  text-align: center;
  background: transparent;
  z-index: 1;
  cursor: pointer;
  font-weight: 600px;
  font-size: 14px;
}

.modal_edit_donation_input_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin-bottom: 20px; */
}

.schedule-dropdown-label {
  font-size: 14px;
  color: #31378B;
  font-weight: 300;
  margin-bottom: 10px;
  margin-top: 10px;
  text-transform: uppercase;
  background-color: #fff;
  position: relative;
  margin-bottom: 5px;
  text-align: center;
  align-content: center;
  align-self: center;
}

.modal_edit_donation_input {
  width: 200px;
  /* max-width: 95%; */
  height: 50px;
  /* padding-left: 10px; */
  color: #1d2129;
  border-radius: 10px;
  margin-bottom: 10px;
  border: solid 0.5px;
  text-align: center;
  background: transparent;
  z-index: 1;
  cursor: pointer;
  font-weight: 600px;
  font-size: 14px;
  align-self: center;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;
  padding: 15px;
}

.modal_edit_donation_input:focus {
  outline: none;
  border: solid 1px #a7a7c7;
}

.schedule_selector_container {
  display: flex;
  position: relative;
  width: 300px;
}

.schedule_selector {
  width: 300px;
  height: 55px;
  padding-left: 10px;
  color: #1d2129;
  border-radius: 50px;
  margin-bottom: 10px;
  border: solid 0.5px;
  text-align: center;
  background: transparent;
  z-index: 1;
  cursor: pointer;
  text-transform: capitalize;
}

.schedule_weekday_override {
  background-color: aqua;
  width: 100%;
  flex-direction: row;
  display: flex;
  flex: 1;

}
.schedule_weekday_container {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  /* background-color: #ED896E; */
}

.schedule_weekday_container_label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border: solid 0.5px;
  border-radius: 50px;
  background-color: #31378B;
  width: 58px;
  height: 25px;
  cursor: pointer;
  margin: 2px;
  margin-bottom: 10px;
}

.schedule_weekday_label {
  font-size: 0.85rem;
  text-transform: capitalize;
  margin-left: 8px;
}

.modal_edit_button_update {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 50px;
  background-color: #31378B;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  font-weight: 600;
}

.modal_edit_button_cancel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 50px;
  background-color: transparent;
  border: solid 1px #31378B;
  color: #31378B;
  font-size: 16px;
  text-transform: uppercase;
  border-radius: 50px;
  margin-bottom: 0px;
  margin-top: 10px;
  cursor: pointer;
  font-weight: 600;
}
