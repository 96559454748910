.auth{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh; 
  background-color: #fff;
  background-image: url('../../assets/images/bk.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  padding-left: 10px;
  padding-right: 10px;
}

.auth_container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.auth_logo{
  width: 160px;
  height: 160px;
}

.auth_title{
  text-align: center;
  color: #31378B;
  font-size: 24px;
  margin-top: 40px;
  margin-bottom: 70px;
  text-transform: uppercase;
}


.auth_link {
  color: #31378B;
  font-weight: 600;
  font-size: 0.9rem;
  padding-left: 1px;
  border: none;
  background-color: transparent;
  text-transform: uppercase;
}

.auth_link:hover {
  color: #31378B;
  text-decoration: underline;
  cursor: pointer;
}

.login-page {
  display: flex;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;
  align-items: center;
}

.login_page_title {
  font-size: 1.6rem;
  font-weight: 400;
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
}

.login_logo-container {
  display: flex;
  position: relative;
  justify-content: center;
  width: 440px;
  margin-bottom: 25px;
}

.login-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 415px;
  background-color: #fff;
  border-radius: 5px;
  padding-top: 40px;
  padding-bottom: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.login-input {
  font-size: 1rem;
  height: 55px;
  border: none;
  width: 100%;
  min-width: 350px;
  padding-left: 20px;
  background: #f9f9f9;
  margin-top: 10px;
  color: #1d2129;
  border-radius: 50px;
  text-transform: uppercase;
}

.login-input:focus {
  outline: none;
  border: solid 1px #1d2129;
}

.login-icons-view {
  position: absolute;
  top: 32px;
  right: 18px;
  color: rgb(142, 142, 142);
  cursor: pointer;
}

.login-button {
  width: 100%;
  min-width: 310px;
  height: 55px;
  margin-top: 25px;
  font-size: 1.1rem;
  font-weight: 600;
  border: none;
  background-color: #31378B;
  color: #fff;
  border-radius: 50px;
  text-transform: uppercase;
}

.login-button:hover {
  filter: brightness(105%);
  cursor: pointer;
}

.login-link {
  color: #200738;
  font-weight: 400;
  font-size: 0.9rem;
  padding-left: 1px;
}

.login-link:hover {
  color: #200738;
  text-decoration: underline;
}

.login-create-account {
  font-size: 0.9rem;
  margin-top: 25px;
  color: #535A66;
  text-transform: uppercase;
}

.forgot_password_link {
  font-size: 0.9rem;
  margin-top: 8px;
  color: #31378B;
  margin-left: 5px;
  text-transform: uppercase;
}

.login-error {
  font-size: 13px;
  margin-left: 5px;
  font-weight: 400;
  margin-top: 15px;
  color: #cf6679;
}

.auth_contact_email{
  font-size: 0.9rem;
  margin-top: 50px;
  color: #535A66;
  text-align: center;
  text-transform: uppercase;
}

.auth_contact_email_link {
  color: #200738;
  font-weight: 600;
  font-size: 0.9rem;
}


@media screen and (max-width: 600px) {}