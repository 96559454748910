.history-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 823px;
    width: 100%;
    margin-bottom: 20px;
    min-height: 72px;
    border-radius: 12px;
    background-color: #f9f9f9;
    padding-left: 20px;
    padding-right: 20px;
}

.history-card-text {
    color: #31378B;
    font-weight: 500;
    text-transform: capitalize;
    margin-bottom: 5px;
}

.history-card-header {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.schedule-card-date-container {
    background-color: #31378B;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    height: 30px;
    min-width: 165px;
    /* max-width: 160px; */
    /* width: 100%; */
    /* max-width: 220px; */
    padding: 8px;
}

.history-card-text-date {
    font-size: 14px;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
}

.schedule-card-total-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin-right: 10px;
    margin-left: 10px; */
}

.history-card-text-total {
    font-size: 14px;
    color: #31378B;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 5px;
    text-align: center;
}

.schedule-card-text-recipient {
    font-size: 14px;
    color: #469EAA;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
}

.edit-icon {
    width: 25px;
    height: 25px;
    font-size: 24px;
    margin: 4px;
    border: none;
}
.trash-icon {
    width: 25px;
    height: 25px;
    margin: 4px;
    border: none;
}

@media screen and (max-width: 600px) {
    .history-card{
        padding-left: 8px;
        padding-right: 8px;
    }
    .schedule-card-date-container {
        min-width: 130px;
    }
    .history-card-text-date {
        font-size: 10px;
    }
    .history-card-text-total {
        font-size: 10px;
    }
    .schedule-card-text-recipient {
        font-size: 10px;
    }
    .edit-icon {
        width: 25px;
        height: 25px;
        font-size: 24px;
        margin: 2px;
        border: none;
    }
    .trash-icon {
        width: 20px;
        height: 20px;
        margin: 2px;
        border: none;
    }
    .history-card-text {
        font-size: 1.5rem;
    }

    .history-card-text-orange {
        font-size: 1.5rem;
    }

    .history-card-text-blue {
        font-size: 1.5rem;
    }

    .schedule-card-date-container{
        width: 160px;
    }
    .history_card_text_date{
        font-size: 13px;
    }
    .history-card-text-total{
        font-size: 14px;
        font-weight: 500;

    }
    .schedule-card-text-recipient{
        font-size: 12px;
        font-weight: 400;
    }
    .schedule-card-text-total-container{
        width: 80px;
        /* margin-right: 10px; */
    }
    .history-card-expanded-donation-title{
        font-size: 13px;
    }
    .history-card-expanded-donation-subtitle{
        font-size: 13px;
    }
    .history-card-expanded-donation-items{
        padding-left: 15px;
        padding-right: 15px;
    }
    .history-card-expanded-donation-link, .history-card-expanded-donation-lbs-text, .history-card-expanded-donation-delivery-text{
        font-size: 12.5px;
    }
}