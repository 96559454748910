.history_card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 823px;
    width: 100%;
    margin-bottom: 20px;
    min-height: 72px;
    border-radius: 12px;
    background-color: #f9f9f9;
    padding-left: 30px;
    padding-right: 30px;
}

.history_card_add_icon {
    display: flex;
    width: 34px;
    height: 34px;
}

.history_card_add_icon:hover {
    cursor: pointer;
}

.history_card_header {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.history_card_date_container {
    background-color: #31378B;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    height: 30px;
    width: 212px;
}

.history_card_total_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.history_card_text_date {
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
}

.history_card_text_total_container{
    margin-right: 50px;
}

.history_card_text_lbs {
    font-size: 16px;
    color: #469EAA;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
}

.history_card_text_total {
    font-size: 24px;
    color: #1F1142;
    font-weight: 300;
    text-transform: uppercase;
    margin-bottom: 5px;
    text-align: center;
}

.history_card_expanded_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 15px;
}

.history_card_expanded_donation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    padding-bottom: 20px;
}

.history_card_expanded_donation_items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-width: 350px;
    border-bottom: 1px solid rgb(216, 216, 216);
    margin-top: 5px;
    padding-bottom: 5px;
}

.history_card_expanded_donation_title {
    font-size: 16px;
    font-weight: 300;
    color: #1F1142;
    text-transform: uppercase;
    flex: 1;
}

.history_card_expanded_donation_subtitle {
    font-size: 16px;
    font-weight: 300;
    color: #1F1142;
    text-transform: uppercase;
    text-align: center;
    align-self: center;
    flex: 1;
}

.history_card_expanded_edit_container{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
}

.history_card_expanded_donation_link{
    font-size: 16px;
    font-weight: 400;
    color: #469EAA;
    text-transform: uppercase;
    margin-bottom: 10px;
    text-align: right;
    text-decoration: underline;
    cursor: pointer;
}

.history_card_expanded_donation_lbs_text {
    font-size: 16px;
    font-weight: 500;
    color: #469EAA;
    text-transform: uppercase;
    margin-bottom: 10px;
    text-align: right;
}

.history_card_expanded_donation_delivery_text {
    font-size: 16px;
    font-weight: 500;
    color: #519DCC;
    text-transform: uppercase;
}

@media screen and (max-width: 600px) {
    .history_card{
        padding-left: 8px;
        padding-right: 8px;
    }
    .history_card_text {
        font-size: 1.5rem;
    }

    .history_card_text_orange {
        font-size: 1.5rem;
    }

    .history_card_text_blue {
        font-size: 1.5rem;
    }

    .history_card_date_container{
        width: 160px;
    }
    .history_card_text_date{
        font-size: 13px;
    }
    .history_card_text_total{
        font-size: 14px;
        font-weight: 500;

    }
    .history_card_text_lbs{
        font-size: 12px;
        font-weight: 400;
    }
    .history_card_text_total_container{
        width: 80px;
        margin-right: 10px;
    }
    .history_card_expanded_donation_title{
        font-size: 13px;
    }
    .history_card_expanded_donation_subtitle{
        font-size: 13px;
    }
    .history_card_expanded_donation_items{
        padding-left: 15px;
        padding-right: 15px;
    }
    .history_card_expanded_donation_link, .history_card_expanded_donation_lbs_text, .history_card_expanded_donation_delivery_text{
        font-size: 12.5px;
    }
}