@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;  font-weight: 400;
}

body{
    background-color:#F6F6F6;
    color: #FFF;
}

ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

a {
	text-decoration: none;
}

option {
	border: none;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #959595;
	opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: #959595;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
	color:#959595;

  }

::-webkit-scrollbar{
	width: 6px;
	height: 6px;
}

::-webkit-scrollbar-track{
	background-color: #e7e5e5;
}	

::-webkit-scrollbar-thumb{
	background-color: rgb(162, 162, 162);
	border-radius: 5px;
}

select, option {
	-webkit-appearance: none;
  }