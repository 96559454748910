/* Merged and unified styles for both React Native and React Web */

.schedule {
    position: absolute;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: #fff;
    background-image: url('../../assets/images/bk.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    min-height: 100vh;
    padding-top: 90px;
    padding-right: 10px;
    padding-left: 320px;
  }
  
  @media (max-width: 850px) {
    .schedule {
      padding-left: 15px;
      padding-bottom: 90px;
    }
  }
  
  .header-page-title-container,
  .schedule_new_feature_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  
  .header-page-title-icon-schedule {
    height: 35px;
    width: 40px;
    margin-right: 10px;
  }
  
  .header-page-title,
  .schedule_new_feature_title {
    font-size: 20px;
    font-weight: 400;
    text-transform: uppercase;
    color: #ED896E;
    text-align: center;
    width: auto;
  }
  
  .schedule-header,
  .schedule_header {
    display: flex;
    flex-direction: row;
    /* border-bottom: 1px solid rgb(216, 216, 216); */
  }
  
  .switch-container {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: stretch;
    align-self: center;
    margin-top: 20px;
    height: 50px;
    min-width: 300px;
    max-width: 500px;
    width: 50%;
  }
  
  .switch-option-left,
  .switch-option-right {
    flex: 1;
    height: 50px;
    padding: 10px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #31378B;
  }
  
  .switch-option-left {
    border-radius: 10px 0 0 10px;
  }
  
  .switch-option-right {
    border-radius: 0 10px 10px 0;
  }
  
  .selected-option-left,
  .selected-option-right {
    background-color: #31378B;
  }
  
  .switch-text {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: #31378B;
  }
  
  .switch-text-selected {
    color: #fff;
  }
  
  .schedule-cards-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-top: 30px;
    margin-bottom: 30px;
    /* background-color: #fff; */
    /* padding-bottom: 100px; */

  }
  
  .floating-button {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 80px;
    height: 80px;
    border-radius: 40px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .plus-icon {
    width: 110%;
    height: 110%;
  }
  
  .run-upcoming-container {
    position: absolute;
    bottom: 100px;
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
  }
  
  .schedule-header-buttons {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  /* Additional web-specific styles */
  .schedule_new_feature_box {
    max-width: 500px;
    min-height: 200px;
    width: 100%;
    margin-bottom: 20px;
    background-color: #fff;
    border: solid 1px #f9f9f9;
    padding: 20px;
    border-radius: 12px;
  }
  
  .schedule_title {
    color: #272525;
    text-align: center;
    font-size: 3.6rem;
    font-weight: 400;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  
  .schedule_container,
  .schedule_weekday_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .schedule_selector,
  .group_food_category_selector {
    width: 300px;
    height: 55px;
    padding-left: 10px;
    color: #1d2129;
    border-radius: 50px;
    margin-bottom: 10px;
    border: solid 0.5px;
    text-align: center;
    background: transparent;
    z-index: 1;
    cursor: pointer;
  }
  
  .schedule_button {
    width: 280px;
    height: 55px;
    background-image: linear-gradient(to right, #33398c, #200738);
    color: #fff;
    font-size: 1.2rem;
    border-radius: 50px;
    border: none;
    cursor: pointer;
  }
  
  .empty-schedule-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 50px;
  }
  
  .empty-schedule-box {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 500px;
    min-height: 200px;
    width: 100%;
    margin-bottom: 20px;
    background-color: #fff;
    border: solid 1px #f9f9f9;
    padding: 20px;
    border-radius: 12px;
  }
  
  .empty-schedule-title {
    color: #ED896E;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 400;
    text-align: center;
    margin-bottom: 10px;
  }
  
  @media (max-width: 850px) {
    .schedule_new_feature_title {
      font-size: 16px;
    }
    .floating-button {
      bottom: 110px;
      right: 20px;
      width: 60px;
      height: 60px;
      border-radius: 30px;
  }
  }
  